/**
 * Accordion banner04
 */

const accordion = () => {
  // Get all accordion buttons
  const accordionButtons = document.querySelectorAll('.js-accordion');

  const toggleAccordion1 = document.querySelector('.js-toggle-accordion-1')

  const panels = document.querySelectorAll('.js-accordion-item');
  const descs = document.querySelectorAll('.js-accordion-item-desc');

  //clicking "view more"
  if(toggleAccordion1) {
    toggleAccordion1.addEventListener('click', () => {
      if(toggleAccordion1.classList.contains('active')) {
        toggleAccordion1.classList.remove('active');
        accordionButtons[0].classList.remove('active');
        panels[0].classList.remove('show');
        descs[0].classList.remove('show');
        accordionButtons[1].classList.remove('active');
        panels[1].classList.remove('show');
        descs[1].classList.remove('show');
      }

      else {
        accordionButtons[0].classList.add('active');
        panels[0].classList.add('show');
        descs[0].classList.add('show');
        toggleAccordion1.classList.add('active');
      }
    })
  }

  //clicking the accordion arrow
  for(let x = 0; x < accordionButtons.length; x ++) {
    if(accordionButtons[x]) {
      accordionButtons[x].addEventListener('click', () => {
        accordionButtons[x].classList.toggle('active');
        panels[x].classList.toggle('show');
        descs[x].classList.toggle('show');
        toggleAccordion1?.classList.add('active');

        for(let y = 0; y < accordionButtons.length; y++) {
          if(y !== x) {
            accordionButtons[y].classList.remove('active');
            panels[y].classList.remove('show');
            descs[y].classList.remove('show');
          }
        }
      })
    }
  }

  const jsLink1 = document.querySelectorAll('.js-link-1')

  if(jsLink1) {
    jsLink1.forEach(item => {
      item.addEventListener('click', (event) => {
        event.preventDefault()

        toggleAccordion1?.classList.toggle('active')

        // Toggle active class on the clicked button
        accordionButtons[0].classList.remove('active');

        // Get the associated accordion panel based on index
        const panels = document.querySelectorAll('.js-accordion-item');
        const descs = document.querySelectorAll('.js-accordion-item-desc');
        const panel = panels[0];
        const desc = descs[0];

        // Check if the panel exists
        if (panel) {
          // Toggle the panel's visibility
          panel.classList.add('show');
          desc.classList.add('show');

          // Close other accordion panels
          panels.forEach((otherPanel, otherIndex) => {
            if (otherIndex !== 0) {
              otherPanel.classList.remove('show');
              accordionButtons[otherIndex].classList.add('active');
            }
          });

          descs.forEach((otherDesc, otherIndex) => {
            if (otherIndex !== 0) {
              otherDesc.classList.remove('show');
              accordionButtons[otherIndex].classList.add('active');
            }
          });

          const href = item.getAttribute('href')

          if(href) {
            const hrefElement = document.querySelector(href)

            if(hrefElement) {
              hrefElement.scrollIntoView()
            }
          }
        }
      })
    })
  }

  const jsLink2 = document.querySelectorAll('.js-link-2')

  if(jsLink2) {
    jsLink2.forEach(item => {
      item.addEventListener('click', (event) => {
        event.preventDefault()

        // Toggle active class on the clicked button
        accordionButtons[1].classList.remove('active');

        // Get the associated accordion panel based on index
        const panels = document.querySelectorAll('.js-accordion-item');
        const descs = document.querySelectorAll('.js-accordion-item-desc');
        const panel = panels[1];
        const desc = descs[1];

        // Check if the panel exists
        if (panel) {
          // Toggle the panel's visibility
          panel.classList.add('show');
          desc.classList.add('show');

          // Close other accordion panels
          panels.forEach((otherPanel, otherIndex) => {
            if (otherIndex !== 1) {
              otherPanel.classList.remove('show');
              accordionButtons[otherIndex].classList.add('active');
            }
          });

          descs.forEach((otherDesc, otherIndex) => {
            if (otherIndex !== 1) {
              otherDesc.classList.remove('show');
              accordionButtons[otherIndex].classList.add('active');
            }
          });

          const href = item.getAttribute('href')

          if(href) {
            const hrefElement = document.querySelector(href)

            if(hrefElement) {
              hrefElement.scrollIntoView()
            }
          }
        }
      })
    })
  }
};

export default accordion;
